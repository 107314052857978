import api from '@/utils/api';
import type { CompanyRoleResponse, CompanyRoleSaveBody, CompanyRolesPermissionsSaveBody } from '@/types/schema';

export const PermissionsService = {
  createCompanyRole(companyId: string, data: CompanyRoleSaveBody): Promise<CompanyRoleResponse[]> {
    return api({
      method: 'post',
      url: `companies/${companyId}/roles`,
      data,
    });
  },
  getCompanyRoles(companyId: string): Promise<CompanyRoleResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/roles`,
    });
  },
  getCompanyRole(companyId: string, roleId: string): Promise<CompanyRoleResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/roles/${roleId}`,
    });
  },
  updateCompanyRole(companyId: string, roleId: string, data: CompanyRoleSaveBody): Promise<CompanyRoleResponse> {
    return api({
      method: 'put',
      url: `companies/${companyId}/roles/${roleId}`,
      data,
    });
  },
  copyCompanyRole(companyId: string, roleId: string): Promise<CompanyRoleResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/roles/${roleId}/copy`,
    });
  },
  deleteCompanyRole(companyId: string, roleId: string): Promise<void> {
    return api({
      method: 'delete',
      url: `companies/${companyId}/roles/${roleId}`,
    });
  },
  updatePermissions(companyId: string, data: CompanyRolesPermissionsSaveBody): Promise<void> {
    return api({
      method: 'put',
      url: `companies/${companyId}/roles/permissions`,
      data,
    });
  },
};
