<template>
  <div>
    <v-select
      v-model="country"
      prepend-inner-icon="mdi-earth"
      :label="label"
      :items="countries"
      no-data-text="Ingen land"
    />
  </div>
</template>

<script>
import countries from '@/utils/countries';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Velg land',
    },
  },
  data: () => ({
    countries,
  }),
  computed: {
    country: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
