import api from '@/utils/api';
import type { CompanyUserResponse } from '@/types/schema';

export const CompanyUsersService = {
  getUsers(companyId: string): Promise<CompanyUserResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/users`,
    });
  },
  revokeUserAccess(companyId: string, userId: string): Promise<void> {
    return api({
      method: 'delete',
      url: `companies/${companyId}/users/${userId}`,
    });
  },
};
