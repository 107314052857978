<template>
  <v-list-item
    :href="item.href"
    :rel="item.href ? 'nofollow' : undefined"
    :target="item.href ? '_blank' : undefined"
    :to="item.to"
    :exact="item.exact"
    color="primary"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list-item-icon v-if="item.icon || item.img">
      <img v-if="item.img" :src="item.img" />
      <v-icon v-else :class="item.iconClass" v-text="item.icon" />
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title v-text="item.text" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
