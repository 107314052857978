import type { Permission } from '@/modules/settings/user-access/permissions';
import type { Middleware } from '../router-types';

export const PermissionsMiddleware: Middleware = async ({ to, redirect, store }) => {
  if (to.meta?.permissions || to.meta?.permissionsSome) {
    const userPermissions = store.getters['company/authUserPermissions'] as Permission[];

    // always allow owners access to /user-access routes
    const isOwner = store.getters['company/isAuthUserOwner'];
    const isUserAccessRoute = to.name && ['UserAccessUsers', 'UserAccessRoles'].includes(to.name);

    if (isOwner && isUserAccessRoute) return;

    const canAccess = to.meta.permissionsSome
      ? to.meta.permissionsSome.some((permission) => userPermissions.includes(permission))
      : to.meta.permissions?.every((permission) => userPermissions.includes(permission));

    if (!canAccess) {
      return redirect({
        name: 'Forbidden',
        params: { companyNameSlug: to.params.companyNameSlug },
        query: { route: to.name },
      });
    }
  }
};
