<template>
  <BaseDialog v-model="dialogVisibleModel" width="700">
    <v-form v-model="formIsValid" @submit.prevent="submit">
      <v-card-title>Hvem skal opprette selskapet?</v-card-title>
      <v-card-text>
        <v-radio-group v-model="scenario" :rules="rules.required">
          <v-radio
            value="redirect"
            label="Jeg ønsker å opprette selskapet på vegne av mitt regnskapsbyrå. Regnskapsbyrået vil
få provisjon og ha tilgang til selskapet."
          />
          <v-radio value="modal">
            <template #label>
              <span>
                Jeg ønsker <span class="text-decoration-underline font-weight-bold">IKKE</span> å opprette selskapet på
                vegne av mitt regnskapsbyrå. Min bruker vil få tilgang til selskapet. Mitt regnskapsbyrå vil ikke få
                provisjon og tilgang til selskapet.
              </span>
            </template>
          </v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('input', false)">Avbryt</v-btn>
        <v-btn color="primary" :loading="loading" :disabled="loading || !formIsValid" depressed type="submit">
          Neste
        </v-btn>
      </v-card-actions>
    </v-form>
  </BaseDialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseDialog from '@/components/BaseDialog.vue';
import validationRules from '@/utils/validationRules';

type Scenario = 'redirect' | 'modal';

type CreateCompanyConfirmationModalData = {
  scenario: Scenario | null;
  loading: boolean;
  formIsValid: boolean;
  rules: typeof validationRules;
};

export default defineComponent({
  components: {
    BaseDialog,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'submit'],
  data: (): CreateCompanyConfirmationModalData => ({
    scenario: null,
    loading: false,
    formIsValid: false,
    rules: validationRules,
  }),
  computed: {
    dialogVisibleModel: {
      get() {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    submit() {
      this.loading = true;
      this.$emit('submit', this.scenario);
      this.loading = false;
    },
  },
});
</script>
