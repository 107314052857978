import api from '@/utils/api';
import type {
  PayRunSaveBody,
  PayRunResponse,
  PayRunTotalsCalculationRequest,
  PayRunTotalsCalculationResponse,
  PayRunResponseImport,
} from '@/types/schema';

export const PayRunService = {
  createPayRun(companyId: string, data: PayRunSaveBody, silenceErrorAlert?: boolean): Promise<PayRunResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/pay_runs`,
      data,
      silenceErrorAlert,
    });
  },
  createPayslipPreview(companyId: string, payload: PayRunSaveBody): Promise<ArrayBuffer> {
    return api({
      method: 'post',
      url: `companies/${companyId}/payslips/preview`,
      responseType: 'arraybuffer',
      data: payload,
    });
  },
  updatePayRun(
    companyId: string,
    salaryId: string,
    data: PayRunSaveBody,
    silenceErrorAlert?: boolean,
  ): Promise<PayRunResponse> {
    return api({
      method: 'patch',
      url: `companies/${companyId}/pay_runs/${salaryId}`,
      data,
      silenceErrorAlert,
    });
  },
  getPayruns(
    companyId: string,
    ignoreCache: boolean,
    startDate: string,
    endDate: string,
    includeReversed: boolean,
  ): Promise<PayRunResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/pay_runs`,
      ignoreCache,
      params: {
        startDate,
        endDate,
        includeReversed,
      },
    });
  },
  getPayrun(companyId: string, salaryId: string): Promise<PayRunResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/pay_runs/${salaryId}`,
      ignoreCache: true,
    });
  },
  sendAMelding(companyId: string, payrunId: string): Promise<PayRunResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/pay_runs/${payrunId}/a_melding`,
    });
  },
  deletePayrun(companyId: string, id: string): Promise<void> {
    return api({
      method: 'delete',
      url: `companies/${companyId}/pay_runs/${id}`,
      ignoreCache: true,
    });
  },
  calculateTotals(companyId: string, data: PayRunTotalsCalculationRequest): Promise<PayRunTotalsCalculationResponse[]> {
    return api({
      method: 'post',
      url: `companies/${companyId}/pay_runs/calculate_totals`,
      data,
      abortKey: 'calculateTotals',
    });
  },
  async downloadAMeldingSent(companyId: string, payrunId: string): Promise<ArrayBuffer> {
    return api({
      method: 'get',
      url: `companies/${companyId}/pay_runs/${payrunId}/a_melding`,
      responseType: 'arraybuffer',
    });
  },
  async downloadAMeldingResponse(companyId: string, payrunId: string): Promise<ArrayBuffer> {
    return api({
      method: 'get',
      url: `companies/${companyId}/pay_runs/${payrunId}/a_melding_response`,
      responseType: 'arraybuffer',
    });
  },
  reversePayrun(companyId: string, payrunId: string): Promise<PayRunResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/pay_runs/${payrunId}/reverse`,
    });
  },
  getSupportedFormats(companyId: string): Promise<string[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/pay_runs/import/supported_formats`,
    });
  },
  downloadTemplate(companyId: string): Promise<ArrayBuffer> {
    return api({
      method: 'get',
      url: `companies/${companyId}/pay_runs/import/template`,
      responseType: 'arraybuffer',
    });
  },
  importPayrun(companyId: string, file: File): Promise<PayRunResponseImport> {
    return api({
      method: 'post',
      url: `/companies/${companyId}/pay_runs/import`,
      file,
    });
  },
};
