import api from '@/utils/api';
import type {
  CompanyAccountingFirmAccessUpdateSaveBody,
  CompanyAccountingFirmInvitationCreateBody,
  CompanyActiveUserAccessesResponse,
  CompanyUserAccessResponse,
  CompanyUserAccessUpdateSaveBody,
  CompanyUserInvitationCreateBody,
} from '@/types/schema';

export const AccessService = {
  getMe(companyId: string): Promise<CompanyActiveUserAccessesResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/access/me`,
    });
  },
  getAccessList(
    companyId: string,
    params?: { searchString?: string; withInactive?: boolean },
  ): Promise<CompanyUserAccessResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/access/list`,
      params,
    });
  },
  deleteInvitation(companyId: string, invitationId: string): Promise<void> {
    return api({
      method: 'delete',
      url: `companies/${companyId}/access/invitations/${invitationId}`,
    });
  },
  resendInvitation(companyId: string, invitationId: string): Promise<void> {
    return api({
      method: 'post',
      url: `companies/${companyId}/access/invitations/${invitationId}/resend`,
    });
  },
  invalidateUserAccess(companyId: string, userId: string): Promise<void> {
    return api({
      method: 'post',
      url: `companies/${companyId}/access/users/${userId}/invalidate`,
    });
  },
  invalidateAccountingFirmAccess(companyId: string, accountingFirmId: string): Promise<void> {
    return api({
      method: 'post',
      url: `companies/${companyId}/access/accounting_firms/${accountingFirmId}/invalidate`,
    });
  },
  grantAccountingFirmOwnerAccess(companyId: string, accountingFirmId: string): Promise<void> {
    return api({
      method: 'post',
      url: `companies/${companyId}/access/accounting_firms/${accountingFirmId}/owner_access`,
    });
  },
  revokeAccountingFirmOwnerAccess(companyId: string, accountingFirmId: string): Promise<void> {
    return api({
      method: 'delete',
      url: `companies/${companyId}/access/accounting_firms/${accountingFirmId}/owner_access`,
    });
  },
  grantUserOwnerAccess(companyId: string, userId: string): Promise<void> {
    return api({
      method: 'post',
      url: `companies/${companyId}/access/users/${userId}/owner_access`,
    });
  },
  revokeUserOwnerAccess(companyId: string, userId: string): Promise<void> {
    return api({
      method: 'delete',
      url: `companies/${companyId}/access/users/${userId}/owner_access`,
    });
  },
  inviteUser(companyId: string, data: CompanyUserInvitationCreateBody): Promise<void> {
    return api({
      method: 'post',
      url: `companies/${companyId}/access/users/invite`,
      data,
    });
  },
  updateUserInvitation(companyId: string, invitationId: string, data: CompanyUserAccessUpdateSaveBody): Promise<void> {
    return api({
      method: 'put',
      url: `companies/${companyId}/access/invitations/${invitationId}/user`,
      data,
    });
  },
  updateUserAccess(companyId: string, userId: string, data: CompanyUserAccessUpdateSaveBody): Promise<void> {
    return api({
      method: 'put',
      url: `companies/${companyId}/access/users/${userId}`,
      data,
    });
  },
  inviteAccountingFirm(companyId: string, data: CompanyAccountingFirmInvitationCreateBody): Promise<void> {
    return api({
      method: 'post',
      url: `companies/${companyId}/access/accounting_firms/invite`,
      data,
    });
  },
  updateAccountingFirmInvitation(
    companyId: string,
    invitationId: string,
    data: CompanyAccountingFirmAccessUpdateSaveBody,
  ): Promise<void> {
    return api({
      method: 'put',
      url: `companies/${companyId}/access/invitations/${invitationId}/accounting_firm`,
      data,
    });
  },
  updateAccountingFirmAccess(
    companyId: string,
    accountingFirmId: string,
    data: CompanyAccountingFirmAccessUpdateSaveBody,
  ): Promise<void> {
    return api({
      method: 'put',
      url: `companies/${companyId}/access/accounting_firms/${accountingFirmId}`,
      data,
    });
  },
};
