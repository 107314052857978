import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { AccountsService } from '@/services/accountsService';

const getDefaultState = () => ({
  loading: false,
  accounts: [],
  accountsPromise: null,
});

const state = getDefaultState();

const actions = {
  async fetchAccounts({ state, commit }, { companyId, refresh = false }) {
    if (state.accountsPromise) {
      return state.accountsPromise;
    }

    if (state.accounts.length && !refresh) {
      return state.accounts;
    }

    commit('SET_LOADING', true);
    const promise = AccountsService.getAccounts(companyId)
      .then((accounts) => {
        commit('SET_ACCOUNTS', accounts);
        return accounts;
      })
      .catch((e) => {
        Sentry.captureException(e);
      })
      .finally(() => {
        commit('SET_PROMISE', null);
        commit('SET_LOADING', false);
      });
    commit('SET_PROMISE', promise);
    return promise;
  },
  async createAccount({ commit }, { companyId, account }) {
    const createdAccount = await AccountsService.createAccount(companyId, account);
    commit('ADD_ACCOUNT', createdAccount);
  },
  async updateAccount({ commit }, { companyId, accountNumber, account }) {
    const updatedAccount = await AccountsService.updateAccount(companyId, accountNumber, account);
    commit('EDIT_ACCOUNT', updatedAccount);
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  },
};

const mutations = {
  SET_ACCOUNTS(state, accounts) {
    state.accounts = accounts;
  },
  SET_PROMISE(state, promise) {
    state.accountsPromise = promise;
  },
  SET_LOADING(state, bool) {
    state.loading = bool;
  },
  ADD_ACCOUNT(state, account) {
    state.accounts.push(account);
  },
  EDIT_ACCOUNT(state, account) {
    const accountIdx = state.accounts.findIndex((acc) => acc.number === account.number);
    Vue.set(state.accounts, accountIdx, account);
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {
  /**
   * Filter out all parent accounts that have sub accounts
   */
  accountsList(state) {
    return state.accounts.filter((account) => !(account.containsSubAccounts && !account.subNumber));
  },
  /**
   * Retrieve an account by its number
   */
  accountByNumber(state) {
    return (accountNumber) => {
      if (!accountNumber) return {};

      const [acc, sub] = accountNumber.toString().split(':');

      return (
        state.accounts.find((account) => {
          const accountNumber = account.number.toString();
          const accountSubNumber = account.subNumber?.toString();

          if (sub) {
            return accountNumber === acc && accountSubNumber === sub;
          } else {
            return accountNumber === acc;
          }
        }) || {}
      );
    };
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
