<template>
  <v-list-group v-model="model" :group="group" no-action v-bind="$attrs">
    <template #activator>
      <v-list-item-icon v-if="item.icon">
        <v-icon :class="item.iconClass" v-text="item.icon" />
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title v-if="item.text" v-text="item.text" />
      </v-list-item-content>
    </template>

    <ListItem
      v-for="(child, i) in item.items.filter((i) => i.canAccess || i.canAccess === undefined)"
      :key="`child-${i}`"
      :item="child"
    />
  </v-list-group>
</template>

<script>
import ListItem from './ListItem.vue';

export default {
  components: {
    ListItem,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    model: null,
  }),
  computed: {
    group() {
      return this.genGroup(this.item.items);
    },
  },
  methods: {
    // builds an array of route paths separated by "|"
    // e.g: /systima-as/payments/settings|/systima-as/chart-of-accounts|/systima-as/salary/settings|/profile
    genGroup(items) {
      return items
        .reduce((acc, cur) => {
          acc.push(cur.items ? this.genGroup(cur.items) : this.resolveRoutePath(cur.to));
          return acc;
        }, [])
        .join('|');
    },
    // if the route is a string, return it
    // if the route is an object, return the path
    resolveRoutePath(route) {
      if (typeof route === 'string') {
        return route;
      }

      const resolvedRoute = this.$router.resolve(route);

      return resolvedRoute.href;
    },
  },
};
</script>
