import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from './routes';
import { runMiddlewarePipeline } from './middleware-pipeline';
import { AuthGuardMiddleware } from './middleware/auth-guard.middleware';
import { PermissionsMiddleware } from './middleware/permissions.middleware';
import { AccessControlGuardMiddleware } from './middleware/access-control-guard.middleware';
import type { PipelineContext } from './router-types';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
});

/**
 * Setup middleware pipeline
 */
router.beforeEach((to, from, next) => {
  let middleware = to.meta?.middleware || [];

  if (!to.meta?.public) {
    middleware = [AuthGuardMiddleware, ...middleware, PermissionsMiddleware, AccessControlGuardMiddleware];
  }

  if (!middleware.length) {
    return next();
  }

  const context: PipelineContext = {
    to,
    from,
    next,
  };

  runMiddlewarePipeline(context, middleware);
});

export default router;
