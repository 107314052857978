import api from '@/utils/api';
import { formatAccountNumber } from '@/utils/accountingHelper';
import type { AccountResponse, AccountCreateBody, AccountUpdateBody } from '@/types/schema';

export interface AccountResponseExtended extends AccountResponse {
  searchboxTitle: string;
  listSearchTitle: string;
  accountFullNumber: string;
}

function accountMapper(account: AccountResponse): AccountResponseExtended {
  const searchboxTitle = `${account.number + (account.subNumber ? ':' + account.subNumber : '')} ${account.name}`;
  const listSearchTitle = `${account.number} ${account.name} ${account.vatCode}`.toLowerCase();
  const accountFullNumber = formatAccountNumber(account);
  return {
    ...account,
    searchboxTitle,
    listSearchTitle,
    accountFullNumber,
    originalName: account.originalName || account.name,
  };
}

export const AccountsService = {
  async getAccounts(companyId: string): Promise<AccountResponseExtended[]> {
    const accounts: AccountResponse[] = await api({
      method: 'get',
      url: `companies/${companyId}/accounts`,
    });

    return accounts.map(accountMapper);
  },
  async getAccount(companyId: string, accountNumber: number): Promise<AccountResponseExtended> {
    const account: AccountResponse = await api({
      method: 'get',
      url: `companies/${companyId}/accounts/${accountNumber}`,
    });

    return accountMapper(account);
  },
  async createAccount(companyId: string, data: AccountCreateBody): Promise<AccountResponseExtended> {
    const account: AccountResponse = await api({
      method: 'post',
      url: `companies/${companyId}/accounts`,
      data,
    });

    return accountMapper(account);
  },
  async updateAccount(
    companyId: string,
    accountNumber: number,
    data: AccountUpdateBody,
  ): Promise<AccountResponseExtended> {
    const account: AccountResponse = await api({
      method: 'patch',
      url: `companies/${companyId}/accounts/${accountNumber}`,
      data,
    });

    return accountMapper(account);
  },
};
