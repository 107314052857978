<template>
  <div>
    <DropZoneContainer
      :full-height="!dashboard && !attachmentViewer"
      @drop="handleFileDrop"
      @error="handleFileDropError"
    >
      <v-tabs v-if="!dashboard" v-model="tab" class="transparent mb-4">
        <v-tab>Ikke godkjente filer</v-tab>
        <v-tab>Godkjente filer</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="transparent fill-height">
        <v-tab-item class="fill-height">
          <v-card class="fill-height">
            <v-card-title
              :class="{
                'px-0': attachmentViewer,
              }"
            >
              <v-row class="no-gutters-y align-center">
                <template v-if="dashboard">
                  <v-col align-self="start">
                    Filer til godkjenning
                    <HelpButton title="Filer til godkjenning">
                      <template #content>
                        <p>
                          Her ser du alle filer som ligger i filmottaket, under fanen “ikke godkjente filer”. Du kan
                          scrolle nedover dersom du har mange filer.
                        </p>

                        <p>
                          Fanen “ikke godkjente filer” er oftest brukt som et utkast, og hvor du godkjenner hvilken
                          filer som skal bokføres. Når du godkjenner en fil blir den flytte ttil “godkjente filer”.
                        </p>

                        <p>
                          Fanen “godkjente filer” er filer som er klare til bokføring. Dersom du har regnskapsfører er
                          dette filene de vil bokføre. Da vet regnskapsføreren at du har godkjent filen og at de kan
                          bokføre bilaget videre.
                        </p>

                        <p>Det er flere måter å få filer til filmottaket:</p>
                        <ul class="mb-4">
                          <li>Mobil app (Systima AS). Du kan da velge om du vil godkjenne filen med en gang</li>
                          <li>EHF (mottak) vil automatisk gå til “ikke godkjente filer”</li>
                          <li>
                            Send et vedlegg til e-postadressen du finner i filmottaket. E-posten er unik for din
                            virksomhet, og alle vedleggs om blir sendt til denne e-posten blir sendt til “ikke godkjente
                            filer”
                          </li>
                          <li>Last opp manuelt</li>
                          <li>Dra og slipp en fil i denne seksjonen eller i filmottaket</li>
                        </ul>
                      </template>
                    </HelpButton>
                  </v-col>
                </template>
                <template v-else>
                  <v-col cols="4">
                    <PeriodPicker
                      :date-from.sync="dateFrom"
                      :date-to.sync="dateTo"
                      clearable
                      @change="fetchFileInboxDebounced"
                    />
                  </v-col>
                  <v-col>
                    <p class="text-body-2 ma-0">Send direkte til filmottaket via:<br />{{ inboxEmail }}</p>
                  </v-col>
                </template>
                <v-col class="d-flex align-start flex-grow-0">
                  <div class="d-inline-block">
                    <v-btn depressed color="primary" class="white--text" @click="showUploadFilesModal = true">
                      Last opp
                      <v-icon right dark> mdi-cloud-upload </v-icon>
                    </v-btn>
                    <div class="text-body-2 text-center grey--text text--darken-2">eller dra og slipp</div>
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text
              :class="{
                'px-0': attachmentViewer,
              }"
            >
              <NonApprovedFileInboxTable
                v-model="selection"
                :headers="headers"
                :items="nonApprovedFiles"
                :loading="loading"
                :row-clicked="changePreview"
                :show-select="!dashboard"
                :height="dashboard || attachmentViewer ? '350px' : 'auto'"
                :fixed-header="dashboard"
                :show-change-status="!attachmentViewer"
                :highlight-selected="!attachmentViewer"
                @delete="toggleDeleteDialog([$event.item])"
                @change:status="updateFileInboxRow($event, { approved: true })"
                @download="downloadAttachmentPDF"
                @edit="openEditModal"
                @openPDFPreview="openPDFPreview"
                @change:selection="$emit('change:selectedFiles', $event)"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="!dashboard" class="fill-height">
          <v-card class="fill-height">
            <v-card-title
              :class="{
                'px-0': attachmentViewer,
              }"
            >
              <v-row class="no-gutters-y align-center">
                <v-col cols="4">
                  <PeriodPicker
                    :date-from.sync="dateFrom"
                    :date-to.sync="dateTo"
                    clearable
                    @change="fetchFileInboxDebounced"
                  />
                </v-col>
                <v-col>
                  <p class="text-body-2 ma-0">Send direkte til filmottaket via:<br />{{ inboxEmail }}</p>
                </v-col>
                <v-col class="d-flex flex-grow-0 align-start">
                  <div class="d-inline-block">
                    <v-btn depressed color="secondary" class="white--text" @click="showUploadFilesModal = true">
                      Last opp
                      <v-icon right dark> mdi-cloud-upload </v-icon>
                    </v-btn>
                    <div class="text-body-2 text-center grey--text text--darken-2">eller dra og slipp</div>
                  </div>
                  <v-btn v-if="!attachmentViewer" depressed color="primary" class="ml-5" @click="selectAllGoToPurchase">
                    Bokfør alle
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text
              :class="{
                'px-0': attachmentViewer,
              }"
            >
              <ApprovedFileInboxTable
                v-model="selection"
                :headers="headers"
                :items="approvedFiles"
                :loading="loading"
                :row-clicked="changePreview"
                :height="dashboard || attachmentViewer ? '350px' : 'auto'"
                :fixed-header="attachmentViewer"
                :show-change-status="!attachmentViewer"
                :show-create-document="!attachmentViewer"
                :highlight-selected="!attachmentViewer"
                @delete="toggleDeleteDialog([$event.item])"
                @change:status="updateFileInboxRow($event, { approved: false })"
                @navigate="navigateToTransaction($event.route, $event.item)"
                @download="downloadAttachmentPDF"
                @edit="openEditModal"
                @openPDFPreview="openPDFPreview"
                @change:selection="$emit('change:selectedFiles', $event)"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <FileInboxBulkActions
        v-if="!dashboard && !attachmentViewer && selection.length"
        :is-approved="tab === TAB.approved"
        :file-id="selection[0].fileId"
        :merge-available="mergeAvailable"
        :loading="bulkLoadingState"
        class="mt-5"
        @status="bulkUpdateFileInbox({ approved: $event })"
        @delete="toggleDeleteDialog(selection)"
        @navigate="navigateToTransaction($event, selection)"
        @split="splitFiles"
        @merge="mergeFiles"
      />
    </DropZoneContainer>
    <BaseDialog v-if="showUploadFilesModal" v-model="showUploadFilesModal" width="700">
      <FileInboxUploadFilesModal
        :warning="dropZoneWarning"
        :selected-files="dropZoneFiles"
        @close="onUploadFilesModalClose"
      />
    </BaseDialog>
    <BaseDialog v-if="deleteDialog" v-model="deleteDialog" width="560">
      <DeleteDialog
        :modal-title="'Slett fil(er)'"
        :modal-text="`Er du sikker på at du vil slette filen(e)?`"
        :commit-delete="deleteFileInboxItems"
        :loading="deleteInProgress"
        @close="
          deleteDialog = false;
          deleteItems = null;
        "
      />
    </BaseDialog>
    <BaseDialog v-if="isEditModalOpen" v-model="isEditModalOpen" width="560">
      <div>
        <v-card-title>Rediger fil</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="selectedItem.description"
            label="Beskrivelse"
            hide-details="auto"
            maxlength="100"
            counter
          />
          <div class="d-flex justify-end mt-4">
            <v-btn text color="secondary" class="mr-5" @click="closeEditModal">Avbryt</v-btn>
            <v-btn depressed color="primary" :loading="descriptionUpdateInProgress" @click="updateDescription">
              Lagre
            </v-btn>
          </div>
        </v-card-text>
      </div>
    </BaseDialog>
    <v-dialog v-model="showPDFPreview" width="1200" :retain-focus="true">
      <v-layout style="background-color: white" pa-2>
        <div class="v-modal__title ml-1">Visning av fil</div>
        <v-spacer></v-spacer>
        <v-btn small icon @click="showPDFPreview = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-layout>
      <div style="height: 80vh" class="white">
        <PDFPreview :raw-data="previewPDFRawData"></PDFPreview>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import moment from 'moment';
import { debounce } from 'lodash-es';
import DeleteDialog from '@/components/DeleteDialog.vue';
import DropZoneContainer from '@/components/DropZoneContainer.vue';
import PeriodPicker from '@/components/PeriodPicker/PeriodPicker.vue';
import BaseDialog from '@/components/BaseDialog.vue';
import PDFPreview from '@/components/PDFPreview.vue';
import HelpButton from '@/components/HelpButton.vue';
import ApprovedFileInboxTable from './ApprovedFileInboxTable.vue';
import NonApprovedFileInboxTable from './NonApprovedFileInboxTable.vue';
import FileInboxBulkActions from './FileInboxBulkActions.vue';
import FileInboxUploadFilesModal from './FileInboxUploadFilesModal.vue';
import { getFileNameFromHeaders, downloadFile } from '@/utils/downloads';
import { handleError } from '@/utils/shared';
import { getTextByError } from '@/utils/errorTextHelper';

const TAB = {
  unapproved: 0,
  approved: 1,
};

export default {
  components: {
    BaseDialog,
    FileInboxUploadFilesModal,
    NonApprovedFileInboxTable,
    ApprovedFileInboxTable,
    DeleteDialog,
    PeriodPicker,
    DropZoneContainer,
    FileInboxBulkActions,
    PDFPreview,
    HelpButton,
  },
  model: {
    prop: 'selectedFiles',
    event: 'change:selectedFiles',
  },
  props: {
    selectedFiles: {
      type: Array,
      default: () => [],
    },
    dashboard: {
      type: Boolean,
      default: false,
    },
    attachmentViewer: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change:selection'],
  data: () => ({
    TAB,
    tab: TAB.unapproved,
    loading: false,
    files: [],
    showUploadFilesModal: false,
    dateFrom: null,
    dateTo: null,
    deleteDialog: false,
    deleteItems: [],
    isEditModalOpen: false,
    selectedItem: null,
    descriptionUpdateInProgress: false,
    deleteInProgress: false,
    dropZoneFiles: [],
    dropZoneWarning: false,
    selection: [],
    bulkLoadingState: {
      split: false,
      merge: false,
      update: false,
    },
    showPDFPreview: false,
    previewPDFRawData: null,
  }),
  computed: {
    headers() {
      return [
        ...(this.dashboard || this.attachmentViewer
          ? [
              {
                text: 'Vis',
                value: 'preview',
                class: 'text-center px-0',
                cellClass: 'px-0 shrink-cell',
                width: 0,
                align: 'center',
                sortable: false,
              },
            ]
          : []),
        { text: 'Beskrivelse', value: 'description' },
        ...(this.dashboard ? [] : [{ text: 'Opplastingsdato', value: 'uploadedAtSort', width: '20%' }]),
        {
          text: '',
          value: 'actions',
          align: 'end',
          cellClass: 'shrink-cell text-no-wrap',
        },
      ];
    },
    inboxEmail() {
      return this.$store.state.currentCompany?.inboxEmail;
    },
    mergeAvailable() {
      return this.selection.length > 1;
    },
    selectableFiles() {
      const fileId = this.$route.query.fileId;

      if (this.attachmentViewer && fileId) {
        return this.files.filter((file) => file.fileId !== fileId);
      }

      return this.files;
    },
    approvedFiles() {
      return this.selectableFiles.filter((file) => file.approved);
    },
    nonApprovedFiles() {
      return this.selectableFiles.filter((file) => !file.approved);
    },
  },
  watch: {
    tab: {
      handler(tab) {
        this.selection = [];

        if (!this.dashboard && !this.attachmentViewer) {
          this.$router
            .replace({
              query: {
                ...this.$route.query,
                tab: tab === TAB.approved ? 'approved' : 'unapproved',
              },
            })
            .catch(() => {});
        }
      },
      immediate: true,
    },
    selectedFiles: {
      handler(selectedFiles) {
        this.selection = selectedFiles;
      },
    },
  },
  mounted() {
    if (!this.attachmentViewer) {
      this.$eventBus.on('fileUploaded', this.fetchFileInbox);
    }
  },
  beforeDestroy() {
    this.$eventBus.off('fileUploaded', this.fetchFileInbox);
  },
  created() {
    this.tab = this.attachmentViewer || this.$route.query.tab === 'approved' ? TAB.approved : TAB.unapproved;

    this.fetchFileInbox();
  },
  methods: {
    ...mapMutations({
      setBulkActionFiles: 'bulkActionFiles/setBulkActionFiles',
    }),
    changePreview(item) {
      if (!this.dashboard && !this.attachmentViewer) {
        this.$router.replace({ query: { ...this.$route.query, fileId: item.fileId } });
      }
    },
    async downloadAttachmentPDF(file) {
      try {
        const { data, headers } = await this.$api.file.downloadFile(this.companyId, file.fileId, true);
        const fileName = getFileNameFromHeaders(headers);

        downloadFile(data, headers['content-type'], fileName);
      } catch (e) {
        this.$store.dispatch('setErrorAlert', 'Kunne ikke laste ned faktura');
        handleError(e);
      }
    },
    toggleDeleteDialog(items) {
      this.deleteDialog = !this.deleteDialog;
      this.deleteItems = items;
    },
    async deleteFileInboxItems() {
      const queue = [];

      this.deleteInProgress = true;

      for (const file of this.deleteItems) {
        const res = this.$api.fileInbox.delete(this.companyId, file.id);
        queue.push(res);
      }

      try {
        await Promise.all(queue);

        this.files = this.files.filter((file) => !this.deleteItems.some((item) => item.id === file.id));
        this.selection = [];

        if (this.deleteItems.some((item) => item.fileId === this.$route.query.fileId)) {
          this.$router.replace({ query: { ...this.$route.query, fileId: undefined } });
        }

        this.toggleDeleteDialog([]);

        this.$emit('update:files');
      } catch (e) {
        this.$store.dispatch('setErrorAlert', getTextByError(e));
      }

      this.deleteInProgress = false;
    },
    async updateFileInboxRow({ item }, payload) {
      try {
        const result = await this.$api.fileInbox.update(this.companyId, item.id, payload);

        this.files = this.files.map((file) => (file.id === item.id ? result : file));
        this.$store.dispatch('setSuccessMessage', payload.approved ? 'Bilag godkjent' : 'Bilag avvist');

        this.$emit('update:files');
      } catch (e) {
        this.$store.dispatch('setErrorAlert', getTextByError(e));
      }
    },
    async bulkUpdateFileInbox(payload) {
      const queue = [];

      this.bulkLoadingState.update = true;

      for (const file of this.selection) {
        const result = this.$api.fileInbox.update(this.companyId, file.id, payload);
        queue.push(result);
      }

      try {
        const updated = await Promise.all(queue);

        this.files = this.files.map((file) => {
          const updatedFile = updated.find((updatedItem) => updatedItem.id === file.id);

          return updatedFile ? updatedFile : file;
        });

        this.$store.dispatch('setSuccessMessage', this.tab === TAB.approved ? 'Bilag avvist' : 'Bilag godkjent');

        this.selection = [];
      } catch (e) {
        this.$store.dispatch('setErrorAlert', getTextByError(e));
      }

      this.bulkLoadingState.update = false;
    },
    fetchFileInboxDebounced: debounce(function () {
      this.fetchFileInbox();
    }, 400),
    async fetchFileInbox() {
      try {
        this.loading = true;
        this.files = await this.$api.fileInbox.fetch(
          this.companyId,
          this.dateFrom && moment(this.dateFrom).format('YYYY-MM-DD'),
          this.dateTo && moment(this.dateTo).format('YYYY-MM-DD'),
        );
      } catch (err) {
        this.$sentry.captureException(err);
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    selectAllGoToPurchase() {
      this.setBulkActionFiles(this.approvedFiles);
      this.navigateToBulkPurchase();
    },
    navigateToBulkPurchase() {
      this.$router.push({
        name: 'PurchaseBulkCreate',
        params: {
          companyNameSlug: this.$route.params.companyNameSlug,
        },
        query: {
          fileId: this.approvedFiles[0].fileId,
        },
      });
    },
    onUploadFilesModalClose(uploaded) {
      const hasApprovedFiles = uploaded.some((file) => file.approved);

      this.dropZoneFiles = [];
      this.dropZoneWarning = false;
      this.showUploadFilesModal = false;

      this.files.push(...uploaded);

      this.$emit('update:files');

      if (hasApprovedFiles) {
        this.$store.dispatch('setSuccessMessage', 'Bilag godkjent');
      }
    },
    openEditModal(item) {
      this.isEditModalOpen = true;
      this.selectedItem = item.clone();
    },
    closeEditModal() {
      this.isEditModalOpen = false;
      this.selectedItem = null;
      this.descriptionUpdateInProgress = false;
    },
    async updateDescription() {
      const { id, description } = this.selectedItem;

      this.descriptionUpdateInProgress = true;

      try {
        const result = await this.$api.fileInbox.update(this.companyId, id, { description });

        this.files = this.files.map((file) => (file.id === id ? result : file));

        this.isEditModalOpen = false;

        this.$store.dispatch('setSuccessMessage', 'Beskrivelse oppdatert');
      } catch (e) {
        this.$store.dispatch('setErrorAlert', getTextByError(e));
      }

      this.descriptionUpdateInProgress = false;
    },
    handleFileDrop({ files, warning }) {
      this.dropZoneFiles = files;
      this.dropZoneWarning = warning;
      this.showUploadFilesModal = true;
    },
    handleFileDropError() {
      this.$store.dispatch('setErrorAlert', 'En eller flere filetyper støttes ikke. Last opp gyldige filtyper.');
    },
    async splitFiles() {
      this.bulkLoadingState.split = true;

      const fileIds = this.selection.map((file) => file.id);

      try {
        const res = await this.$api.fileInbox.split(this.companyId, fileIds);

        this.files = this.files.filter((file) => !fileIds.includes(file.id)).concat(res);

        this.selection = [];
      } catch (e) {
        this.$store.dispatch('setErrorAlert', getTextByError(e));
      }

      this.bulkLoadingState.split = false;
    },
    async mergeFiles() {
      this.bulkLoadingState.merge = true;

      const fileIds = this.selection.map((file) => file.id);

      try {
        const res = await this.$api.fileInbox.merge(this.companyId, fileIds);

        this.files = this.files.filter((file) => !fileIds.includes(file.id));
        this.files.push(res);

        this.selection = [];
      } catch (e) {
        this.$store.dispatch('setErrorAlert', getTextByError(e));
      }

      this.bulkLoadingState.merge = false;
    },
    navigateToTransaction(action, item) {
      const routeConfig = {
        name: action,
        params: {
          companyNameSlug: this.$route.params.companyNameSlug,
        },
      };

      if (Array.isArray(item)) {
        routeConfig.query = {
          bulkFileCreate: 'true',
          fileId: item[0].fileId,
        };
        this.setBulkActionFiles(this.selection);
      } else {
        routeConfig.query = {
          fileId: item.fileId,
        };
      }

      this.$router.push(routeConfig);
    },
    openPDFPreview(row) {
      this.$api.file
        .downloadFile(this.companyId, row.fileId)
        .then((res) => {
          this.previewPDFRawData = res;
          this.showPDFPreview = true;
        })
        .catch((err) => {
          this.$store.dispatch('setErrorAlert', getTextByError(err));
          handleError(err);
        });
    },
  },
};
</script>
